import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tile", "kind"]

  connect() {
    this.setSelectedTile()
  }

  // Respond to tile click events
  selectTile(event) {
    event.preventDefault() // To prevent any default action if needed
    this.tileTargets.forEach((element) => {
      element.classList.remove("active")
    })

    const tile = event.currentTarget
    tile.classList.add("active")

    const value = tile.getAttribute("data-value")

    if (value) {
      this.kindTarget.value = value
    }
  }

  // Set the selected tile based on the select element's value
  setSelectedTile() {
    const selectedValue = this.kindTarget.value

    this.tileTargets.forEach((element) => {
      if (element.getAttribute("data-value") === selectedValue) {
        element.classList.add("active")
      }
    })
  }
}
