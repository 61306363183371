import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["flashMessage"]

  declare readonly flashMessageTargets: HTMLElement[]

  connect() {
    this.hideFlashMessagesAfterDelay()
  }

  hideFlashMessage(event: Event) {
    const targetElement = event.currentTarget as HTMLElement
    this.fadeOutAndRemove(targetElement.parentElement)
  }

  hideFlashMessagesAfterDelay() {
    setTimeout(() => {
      this.flashMessageTargets.forEach((element) =>
        this.fadeOutAndRemove(element)
      )
    }, 10000)
  }

  fadeOutAndRemove = (element: HTMLElement | null) => {
    if (element) {
      element.classList.add("fade-out")
      element.addEventListener(
        "animationend",
        () => {
          element.remove()
        },
        { once: true }
      )
    }
  }
}
